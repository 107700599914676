/* eslint-disable prettier/prettier */
import React from 'react'
import { BullsFooterProps } from './types'
import { Column, Container, Row } from '@/components/base/gridview'
import Anchor from '@/components/base/anchor'
import Image from '@/components/base/image/Image'
import { useApp } from '@wap-client/core'
import IconList, { IconListItem } from '@/components/base/icon-list'

import Icon from '@/components/base/icon'
import LangSwitcher from './LangSwitcher'

const Footer: React.FC<BullsFooterProps> = ({ logo, menu, bottomMenu }) => {
  const app = useApp()
  return (
    <>
      <footer className="footer">
        <Container size="extended">
          <div className="footer-in">
            <Row>
              <div className="footer-left-part">
                <Column>
                  <Anchor href={app.settings.homepage}>
                    {logo && (
                      <Image
                        src={typeof logo.src === 'string' ? logo.src : ''}
                        alt=""
                        width={200}
                        height={200}
                      />
                    )}
                  </Anchor>
                </Column>
                <Column>
                  <div>
                    <IconList>
                      {app.settings.socials['facebook'] && (
                        <Anchor
                          href={app.settings.homepage}
                          onClick={() =>
                            window.open(app.settings.socials['facebook'], '_blank')
                          }
                        >
                          <Icon name="icon-facebook" size={'small'} />
                        </Anchor>
                      )}
                      {app.settings.socials['twitter'] && (
                        <Anchor
                          href={app.settings.homepage}
                          onClick={() =>
                            window.open(app.settings.socials['twitter'], '_blank')
                          }
                        >
                          <Icon name="icon-twitter" size={'small'} />
                        </Anchor>
                      )}
                      {app.settings.socials['instagram'] && (
                        <Anchor
                          href={app.settings.homepage}
                          onClick={() =>
                            window.open(app.settings.socials['instagram'], '_blank')
                          }
                        >
                          <Icon name="icon-instagram" size={'small'} />
                        </Anchor>
                      )}
                      {app.settings.socials['linkedin'] && (
                        <Anchor
                          href={app.settings.homepage}
                          onClick={() =>
                            window.open(app.settings.socials['linkedin'], '_blank')
                          }
                        >
                          <Icon name="icon-linkedin" size={'small'} />
                        </Anchor>
                      )}
                      {app.settings.socials['youtube'] && (
                        <Anchor
                          href={app.settings.homepage}
                          onClick={() =>
                            window.open(app.settings.socials['youtube'], '_blank')
                          }
                        >
                          <Icon name="icon-youtube" size={'small'} />
                        </Anchor>
                      )}
                      <div className="footer-social-usename">/bullsgirisim</div>
                    </IconList>
                  </div>
                </Column>
              </div>
              <Column>
                <Row xl={{ align: 'center', justify: 'start' }}>
                  <ul className="footer-menu">
                    {Array.isArray(menu) &&
                      menu?.map((menuItem, index) => (
                        <li
                          key={index}
                          className={`${menuItem.children.length > 0 ? 'hasSubMenu' : ''
                            }`}
                        >
                          {menuItem.children.length ? (
                            <Anchor onClick={(e) => e.preventDefault()}>
                              {menuItem.title}
                            </Anchor>
                          ) : (
                            <Anchor
                              href={menuItem.href}
                              dangerouslySetInnerHTML={{
                                __html: menuItem.title,
                              }}
                            />
                          )}
                          {menuItem.children.length ? (
                            <ul className="footer-menu-submenu">
                              {menuItem.children.map(
                                (subItem: any, subIndex: number) => (
                                  <li key={subIndex}>
                                    <Anchor
                                      href={subItem.href}
                                      onClick={(e) =>
                                        subItem.children.length &&
                                        e.preventDefault()
                                      }
                                    >
                                      {subItem.title}
                                    </Anchor>
                                  </li>
                                )
                              )}
                            </ul>
                          ) : (
                            <></>
                          )}
                        </li>
                      ))}
                  </ul>
                </Row>
              </Column>
            </Row>
            <div className="footer-bottom-part">

              <div
                dangerouslySetInnerHTML={{
                  __html: app.settings.translations['copyright'],
                }}
              />
              <ul className="footer-bottom-part-menu">
                {Array.isArray(bottomMenu) &&
                  bottomMenu?.map((menuItem: any, index: number) => (
                    <li key={index}>
                      <Anchor href={menuItem.href}>
                        {menuItem.title}
                      </Anchor>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </Container>
      </footer>
      {/* <LangSwitcher /> */}
    </>
  )
}

export default Footer
